import request from '@/utils/request'

// 物资需求模板
export function templateMaterialPage (parameter) {
  return request({
    url: '/api/enterprise/employmentEmploy/workbenchController/queryTemplateMaterialByPaging',
    method: 'get',
    params: parameter
  })
}


// 物资需求模板详情
export function templateMaterialDetail(parameter) {
  return request({
    url: `/api/back/employmentEmploy/templateMaterial/${parameter.id}`,
    method: 'get',
    params: parameter
  })
}


// 保存为我的模板
export function SaveMaterialDemand(parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterprisePublishMaterialDemandController`,
    method: 'POST',
    data: parameter
  })
}
// 我的模板
export function MaterialList(parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseIndexController/queryMyTemplate`,
    method: 'get',
    params: parameter
  })
}

// 我的模板-系统模板
export function systemMaterialList(parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseIndexController/querySysTemplate`,
    method: 'get',
    params: parameter
  })
}

// 删除我的模板
export function templateMaterialDetete(parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/workbenchController/deleteTemplateDemand`,
    method: 'DELETE',
    params: parameter
  })
}

// 根据模板id分页查询模板详情
export function getTemplateDetails(parameter) {
  return request({
    url: `/api/back/employmentEmploy/templateMaterial/page`,
    method: 'get',
    params: parameter
  })
}

// 根据模板id不分页查询模板详情
export function getTemplateDetailsById(id) {
  return request({
    url: `/api/back/employmentEmploy/templateMaterial/${id}`,
    method: 'get',
    params: {
      id
    }
  })
}

