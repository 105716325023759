<template>
  <div class="material__template__wrapper">
    <div class="body_content">
      <div id="common_box">
        <div class="commom_title">
          <a-row type="flex">
            <a-col :span="16">
              <a-row>
                <a-col :span="1">
                  <div class="commom_line"></div>
                </a-col>
                <a-col :span="10">用推荐清单采购</a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
        <div class="content">
          <div class="left_content">
            <div class="left_content_header">
              <div
                @click="handleClickTemplate('system')"
                :class="{
                  template_btn: true,
                  active: templateType === 'system'
                }"
              >系统模版</div>
              <div
                :class="{
                  template_btn: true,
                  active: templateType === 'my'
                } "
                @click="handleClickTemplate('my')"
              >我的模版</div>
            </div>
            <div class="left_content_search">
              <a-input-search
                enter-button="搜索"
                @search="handleGetTempalte"
                v-model="templateName"
                placeholder="请输入模板名称"
                allowClear
                class="search_inp"
              />
            </div>
            <div class="left_content_body">
              <a-spin :spinning="leftLoading">
                <a-list :data-source="templateData">
                  <a-list-item
                    slot="renderItem"
                    slot-scope="item, index"
                    @click="handleClickItem(item)"
                    :class="{active: item.templateId === temId}"
                  >
                    <span>{{ item.templateName }}</span>
                  </a-list-item>
                </a-list>
              </a-spin>
            </div>
          </div>
          <div class="right_content">
            <div class="right_content_title">模板采购说明</div>
            <div
              class="right_content_details"
            >采购清单模版为行业内资深专家，根据多年项目经验提供的项目采购清单模版，模版仅供参考。可根据模版提供的采购清单快速查看商城中对应的商品，进行比价下单购买。不同规模项目使用模版可能会有一定差异性，您可根据项目实际情况自定义模版并保存到“我的模版”。</div>
            <div class="right_content_title checkList">推荐采购清单</div>
            <div class="right_content_btn">
              <div class="left">
                <div class="label">模版名称：</div>
                <div class="value">{{ name }}</div>
              </div>
              <a-button type="link" :icon="icon" @click="handleUpdateTem">{{ modalTitle }}</a-button>
            </div>
            <div class="right_content_table">
              <p-table
                :columns="columns"
                ref="table"
                :source-data="getDataApi"
                extraHeight="650"
                :scroll="{y: 300}"
              >
                <template slot="operation" slot-scope="text,record">
                  <router-link
                    :to="{
                      path: '/supplies/supplies-shop',
                      query: {
                        name: record.commodityName
                      }
                    }"
                  >去商城采购</router-link>
                </template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <a-modal
        :title="modalTitle"
        :visible="outVisible"
        @ok="handleOk"
        okText="保存成为我的模板"
        cancelText="取消"
        :confirmLoading="confirmLoading"
        width="60%"
        @cancel="handleCancel('outVisible')"
      >
        <a-row type="flex" justify="center" :gutter="20">
          <a-col :span="2">
            <div class="modal_text">模板名称</div>
          </a-col>
          <a-col :span="6">
            <a-input placeholder="请输入模板名称" v-model="temName" />
          </a-col>
        </a-row>
        <div class="material_table">
          <a-table
            :scroll="{y: 300}"
            :columns="columns"
            :data-source="cardTableList"
            :pagination="false"
          >
            <template slot="operation" slot-scope="text,record,index">
              <a-button type="link" @click="update(record,index)">编辑商品</a-button>
              <a-popconfirm
                title="确认删除当前数据嘛?"
                ok-text="是的"
                cancel-text="取消"
                @confirm="confirm(index)"
              >
                <a-button type="link">删除</a-button>
              </a-popconfirm>
              <!--<a-button type="link" @click="getData(record,'2')">-->
              <!--修改-->
              <!--</a-button>-->
            </template>
          </a-table>
        </div>
        <a-row type="flex" justify="center" :gutter="20">
          <a-col :span="2">
            <a-button type="link" icon="plus-circle" class="material_btn" @click="add">添加商品</a-button>
          </a-col>
        </a-row>
      </a-modal>

      <a-modal
        :visible="innerVisible"
        @cancel="handleCancel('innerVisible')"
        :title="modalInnerTitle"
      >
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item ref="commodityName" label="商品名称" prop="commodityName">
            <a-input v-model="form.commodityName" placeholder="请输入商品名称" />
          </a-form-model-item>
          <!-- <a-form-model-item ref="specifications" label="规格型号" >
            <a-input v-model="form.specifications" placeholder="请输入规格型号" />
          </a-form-model-item> -->
        </a-form-model>
        <template slot="footer">
          <a-button @click="handleCancel('innerVisible')">取消</a-button>
          <a-button @click="onSubmit" type="primary">确定</a-button>
        </template>
      </a-modal>

      <a-modal
        title="保存模版"
        :visible="visibleSave"
        @ok="handleOk"
        @cancel="handleCancel('visibleSave')"
      >
        <div class="material_tips">
          您的用工需求模版已成功保存，
          <br />可在“控制台-模版管理”进行编辑与查看。
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import {
  MaterialList,
  systemMaterialList,
  SaveMaterialDemand,
  templateMaterialDetail,
  templateMaterialDetete,
  templateMaterialModfly,
  templateMaterialPage,
  getTemplateDetails,
  getTemplateDetailsById
} from '@/api/material'
import { tree } from '@/api/employmentUser/menu'
const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    customRender: (t, r, i) => i + 1
  },
  {
    title: '商品名称',
    dataIndex: 'commodityName',
    key: 'commodityName'
  },
  // {
  //   title: '模板名称',
  //   dataIndex: 'templateName',
  //   key: 'templateName'
  // },
  // {
  //   title: '推荐规格型号',
  //   dataIndex: 'specifications',
  //   key: 'specifications'
  // },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' }
  }
]

const templateParams = {
  pageIndex: 1,
  pageSize: 1000
}

export default {
  data () {
    return {
      templateName: '',
      leftLoading: false,
      templateType: 'system',
      templateData: [],
      temId: '',
      leftRow: {},
      outVisible: false,
      innerVisible: false,
      visibleSave: false,
      temName: '',
      cardTableList: [],
      edit: false,
      editIndex: 0,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      form: {
        commodityName: '',
        specifications: ''
      },
      rules: {
        commodityName: [{ required: true, message: '请输入商品名字', trigger: 'blur' }],
        specifications: [{ required: true, message: '请输入规格型号', trigger: 'blur' }]
      },
      queryParam: {
        templateId: '',
        templateName: '',
        id: ''
      },

      modleData: [],
      tableData: [],
      columns,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        hideOnSinglePage: true,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: total => `总条数：${total}`
      },
      obj: {},
      title: '',
      list: [],
      confirmLoading: false
    }
  },
  // mounted() {
  //   this.init()
  //   this.TemplateList()
  //   let obj = {
  //     pageIndex: 1,
  //     pageSize: 100
  //   }
  //   MaterialList(obj)
  //     .then(res => {
  //       this.list = res.data
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // },
  computed: {
    name () {
      return this.leftRow.templateName
    },
    modalTitle () {
      return this.templateType === 'system' || !this.temId ? '新增模板' : '编辑模板'
    },
    modalInnerTitle () {
      return !this.edit ? '新增商品' : '编辑商品'
    },
    icon () {
      return this.templateType === 'my' && this.temId ? 'edit' : 'plus-circle'
    }
  },
  created () {
    this.handleGetTempalte()
  },
  methods: {
    handleGetTempalte () {
      this.leftLoading = true
      const { templateName, templateType } = this
      const fun = templateType === 'my' ? MaterialList : systemMaterialList
      fun({
        ...templateParams,
        templateName
      })
        .then(res => {
          this.templateData = res.data
        })
        .finally(() => {
          this.leftLoading = false
        })
    },
    handleClickTemplate (type) {
      if (this.templateType !== type) {
        this.templateType = type
        this.handleGetTempalte()
        this.handleClickItem()
      }
    },
    handleClickItem (leftRow = {}) {
      if (leftRow.templateId !== this.temId) {
        this.temId = leftRow.templateId
        this.leftRow = leftRow
      } else {
        this.temId = ''
        this.leftRow = {}
      }
      this.$refs.table.initPage()
    },
    getDataApi (params) {
      if (this.temId) {
        return getTemplateDetails({
          ...params,
          templateId: this.temId
        })
      }
      return Promise.resolve({})
    },
    handleUpdateTem () {
      if (this.temId) {
        getTemplateDetailsById(this.temId).then(res => {
          this.cardTableList = res.data
        })
      } else {
        this.cardTableList = []
      }
      this.temName = this.name
      this.outVisible = true
    },
    TemplateList () {
      const params = this.handleGetQuery()
      templateMaterialPage(params)
        .then(res => {
          res.data.forEach((v, i) => {
            v.key = i + 1
          })
          this.modleData = res.data
          this.pagination.total = res.totalCount
          this.queryParam.templateName = res.data[0].templateName
          this.queryParam.id = res.data[0].id
        })
        .catch(err => {
          console.log(err)
        })
    },

    searchTemplate () {
      this.list = []
      const obj = {
        pageIndex: 1,
        pageSize: 100,
        templateName: this.queryParam.templateName
      }
      MaterialList(obj)
        .then(res => {
          this.list = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    Search () {
      this.pagination.pageIndex = 1
      this.tableData = []
      this.init()
    },
    getTemplate (item) {
      this.pagination.pageIndex = 1
      this.queryParam.templateName = item.templateName
      this.queryParam.templateId = item.templateId
      this.queryParam.id = item.id
      this.templateName = item.templateName
      this.TemplateList()
    },
    handleGetQuery () {
      const { current: pageIndex, pageSize } = this.pagination
      const { templateName } = this
      return {
        pageIndex,
        pageSize,
        templateName
      }
    },
    update (row, index) {
      this.form = {
        ...row
      }
      this.editIndex = index
      this.edit = true
      this.innerVisible = true
    },
    add () {
      this.innerVisible = true
      this.form = {
        commodityName: '',
        specifications: ''
      }
    },
    handleAddFun () {
      const model = {
        commodityList: this.cardTableList,
        templateName: this.temName
      }
      SaveMaterialDemand(model)
        .then(() => {
          this.$message.success(`${this.modalTitle}成功`)
          this.handleCancel('outVisible')
          if (this.templateType === 'my') {
            this.handleGetTempalte()
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleEditFun () {
      const model = {
        commodityList: this.cardTableList,
        templateName: this.temName,
        templateId: this.temId
      }
      SaveMaterialDemand(model)
        .then(() => {
          this.$message.success(`${this.modalTitle}成功`)
          this.handleCancel('outVisible')
          this.temId = null
          this.leftRow = {}
          this.$refs.table.refresh()
          if (this.templateType === 'my') {
            this.handleGetTempalte()
          }
        })
        .finally(() => {
          this.confirmLoading = false
        })
    },
    handleOk () {
      if (!this.cardTableList.length) {
        this.$message.error('请添加商品')
        return
      }
      if (!this.temName) {
        this.$message.error('请输入模板名称')
      } else {
        this.confirmLoading = true
        if (this.templateType === 'my' && this.temId) {
          this.handleEditFun()
        } else {
          this.handleAddFun()
        }
      }
    },
    onSubmit () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.edit) {
            this.cardTableList.splice(this.editIndex, 1, { ...this.form })
          } else {
            this.cardTableList.push({ ...this.form })
          }
          this.$message.success(`${this.modalInnerTitle}成功`)
          this.handleCancel('innerVisible')
        }
      })
    },
    confirm (index) {
      this.cardTableList.splice(index, 1)
    },
    handleTableChange (pagination, filters, sorter) {
      console.log(pagination)
      const pager = { ...this.pagination }
      pager.current = pagination.current
    },
    init () {
      const params = this.handleGetQuery()
      templateMaterialPage(params)
        .then(res => {
          res.data.forEach((v, i) => {
            v.key = i + 1
          })
          this.tableData = res.data
          this.pagination.total = res.totalCount
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleCancel (type) {
      // this.visible = false
      // this.visibleSave = false
      // this.visibleAdd = false
      if (type === 'innerVisible') {
        this.$refs.ruleForm.resetFields()
      }
      this[type] = false
      this.edit = false
    }
  }
}
</script>

<style lang="less">
@import url('../../common/style');
@import url('./index');
.material__template__wrapper {
  .template_btn {
    width: 110px;
    height: 36px;
    background: #dedede;
    margin: 20px 0;
    cursor: pointer;
    text-align: center;
    line-height: 36px;
    color: #fff;
    &.active {
      background: #1677ff;
    }
  }
  .material_table {
    height: 300px;
  }
  .content {
    display: flex;
    .left_content {
      width: 260px;
      margin-right: 30px;
      .left_content_header {
        display: flex;
        justify-content: space-between;
      }
      .left_content_body {
        height: calc(100% - 145px);
        overflow: auto;
        margin-top: 10px;
        .ant-list-item {
          background: #ffffff;
          border: none;
          padding-left: 26px;
          cursor: pointer;
          &:hover {
            background: #f0f0f0;
            color: #1677ff;
          }
          &.active {
            background: #f0f0f0;
            color: #1677ff;
          }
        }
      }
    }
    .right_content {
      flex: 1;
      padding-left: 60px;
      .right_content_title {
        font-size: 20px;
        font-weight: 550;
        color: #333333;
        margin: 14px 0;
        position: relative;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: -50px;
          top: -7px;
          width: 50px;
          height: 50px;
          background: no-repeat url('/img/instruction.png');
          background-size: 100% 100%;
          transform: scale(0.75);
        }
        &.checkList {
          &::before {
            background: no-repeat url('/img/checkList.png');
          }
        }
      }
      .right_content_details {
        margin-bottom: 40px;
      }
      .right_content_btn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .left {
          display: flex;
          .label {
            font-weight: 500;
            font-size: 16px;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
