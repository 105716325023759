import request from '@/utils/request'

export function menuDetail (id) {
  return request({
    url: '/api/back/employmentUser/menu/' + id,
    method: 'get'
  })
}

export function menuAdd (data) {
  return request({
    url: '/api/back/employmentUser/menu',
    method: 'post',
    data
  })
}

export function menuEdit (data) {
  return request({
    url: '/api/enterprise/enterpriseClientAuthController/editMenuInfo',
    method: 'put',
    data
  })
}

export function menuDelete (params) {
  return request({
    url: '/api/back/employmentUser/menu',
    method: 'delete',
    params
  })
}

export function menuPage (params) {
  return request({
    url: '/api/back/employmentUser/menu/page',
    method: 'get',
    params
  })
}

export function allMenu () {
  return request({
    url: '/api/back/employmentUser/menu',
    method: 'get'
  })
}

export function tree (params) {
  return request({
    url: '/api/back/employmentUser/menu/tree',
    method: 'get',
    params
  })
}
export function getListByParentId (params) {
  return request({
    url: '/api/back/employmentUser/menu/listByParentId',
    method: 'get',
    params
  })
}
export default { menuPage, menuAdd, menuEdit, menuDelete, menuDetail,allMenu }
